<template>
    
    <tabla-datos :botones="true" :columnas="columnas" :datos="expedientes" :filtros="filtros"
        :filtrosBuscador="filtrosBuscador" :total="total" @quieroDatos="obtenerExpedientes" sortField="codigo">
    </tabla-datos>
   
   </template>
   
   <script>
   import { PwgsApi } from '../../../services/PwgsApi';
   import TablaDatos from '../../TablaDatos.vue';
   import { FilterMatchMode } from 'primevue/api';
   
   export default {
       components: {
         
           "tabla-datos": TablaDatos,
       },
       props:{
         tipo_servicio: String
       }
       ,
       data() {
           return {
               total: '',
               expedientes: [],
               parametros: '',
               filtrosBuscador: ['codigo'],
               filtros: {
                   'codigo': { value: "", matchMode: FilterMatchMode.CONTAINS },
               },
               columnas: [
                   {
                       header: 'Expediente',
                       field: 'codigo',
                       sortable: true,
                   },
                   {
                       header: 'Fecha',
                       field: 'fecha_eliminacion',
                       sortable: true,
                   },
                   {
                       header: 'Tramitador',
                       field: 'tramitador',
                       sortable: true,
                   },
                   {
                       header: 'Pendiente de',
                       field: 'pendiente',
                       sortable: true,
                   },
                   {
                       header: 'Tipo',
                       field: 'tipo_dano',
                       sortable: true,
                   },
                   {
                       header: 'Días Apertura',
                       field: 'dias_apertura',
                       sortable: true,
                   },
                   {
                       header: 'Días Sin Actualizar',
                       field: 'dias_sin_actualizar',
                       sortable: true,
                   },
                   {
                       header: 'Perito',
                       field: 'perito',
                       sortable: true,
                   },
                   {
                       header: 'Estado',
                       field: 'estado',
                       sortable: true,
                   },
                   {
                       data: null,
                       acciones: [
                           {
                               nombre: 'Editar',
                               class: 'editar',
                               tipo: 'default',
                               nombreId: 'idservicios',
                               icono: 'far fa-edit',
                               // Debe ser arrow function para que this sea este componente
                               accion: (id) => {
                                   console.log('Editar expediente ' + id);
                                   this.$router.push({
                                       name: 'Servicio',
                                       params: {
                                           id:id
                                       }
                                   });
                               }
                           },
                       ]
                   }
               ]
           }
       },
       methods: {
           //https://sonia.pwgs.app/modulos/pwgsapi/index.php/listados-servicios/activos
           async obtenerExpedientes(parametros) {
               const params = parametros;
               this.columnas[0].header = this.$t("general.expediente");
               this.columnas[1].header = this.$t("general.fecha");
               this.columnas[2].header = this.$t("general.tramitador");
               this.columnas[3].header = this.$t("general.pendientede");
               this.columnas[4].header = this.$t("general.tipo");
               this.columnas[5].header = this.$t("general.diasapertura");
               this.columnas[6].header = this.$t("general.diassinactualizar");
               this.columnas[7].header = this.$t("general.perito");
               this.columnas[8].header = this.$t("general.estado");
               const api = new PwgsApi;
               const aux = await api.post('listados-servicios/'+this.tipo_servicio, params);
               this.expedientes = aux.datos;
               this.total = aux.n_total_registros;

               this.$emit('evento_total_asignaciones', this.total); //Es llamado por ./Escritorio.vue
           },
       },
       activated() {
           this.obtenerExpedientes();
       },
           mounted() {
           //this.obtenerExpedientes();
       }
   }
   </script>
   <style>
   .p-dialog {
       width: 90%;
   }
   .p-datatable-header{
   
       visibility:hidden;
   }
   
   </style>